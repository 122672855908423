<template>
	<div class="newJob">
		<w-navTab titleText="公示"></w-navTab>
		<div class="container">
			<div class="body">
				<w-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
					<div class="body-item" v-for="(item,index) in groupList" :key="index"
						@click="$router.push({ name: 'newJobDetail',params:{id: item.id}})">
						<div class="body-item-title">{{item.title}}</div>
						<div class="body-item-buttom">
							<div class="item-buttom-left">
								<div class="buttom-left-icon">
									<img src="@/assets/img/icon210.png" alt="" />
								</div>
								<div class="buttom-left-text">上饶市平安义警协会</div>
							</div>
							<div class="item-buttom-time">{{item.create_time}}</div>
						</div>
					</div>
				</w-list>
			</div>
		</div>
	</div>
</template>

<script>
	import NewsApi from '@/api/news';
	import VantVendor from '@/vendor/vant';
	import WList from '@/components/List';

	export default {
		name: 'newJob',
		data() {
			return {
				loading: true,
				finished: false,
				isEmpty: false,
				paramsPage: 1,
				paramsLimit: 10,
				groupList: []
			}
		},
		created() {
			this.onLoad()
		},
		methods: {
			onLoad() {
				this.loading = true;

				let params = {
					page: this.paramsPage,
					limit: this.paramsLimit,
				}

				NewsApi.groupList(params)
					.then(result => {
						this.paramsPage++;
						this.groupList = this.groupList.concat(result.data);

						this.loading = false;

						if (this.groupList.length <= 0) {
							this.isEmpty = true;
						}

						if (this.groupList.length >= result.data.length) {
							this.finished = true;
						}
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			}
		},
		components: {
			WList,
		}
	}
</script>

<style scoped lang="less">
	.newJob {
		width: 100%;
		min-height: 100vh;
		background-color: #F2F2F2;

		.container {
			padding: 20px 16px;
			box-sizing: border-box;

			.body {
				.body-item {
					padding: 12px;
					box-sizing: border-box;
					border-radius: 4px;
					margin-bottom: 12px;
					background-color: #FFF;

					.body-item-title {
						font-size: 16px;
						line-height: 24px;
						color: #333;
					}
					
					.body-item-buttom{
						margin-top: 4px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						
						.item-buttom-left{
							display: flex;
							align-items: center;
							
							.buttom-left-icon{
								width: 12px;
								min-width: 12px;
								height: 12px;
								
								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}
							.buttom-left-text{
								margin-left: 5px;
								font-size: 12px;
								color: #666;
							}
						}
						.item-buttom-time{
							font-size: 12px;
							line-height: 18px;
							color: #999;
						}
					}
				}
			}
		}
	}
</style>